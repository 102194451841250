<template>
  <page-content :title="device.nickname">

<!--    <div class="device-name" slot="title-extra" @click="editDevice">{{ device.nickname }}-->
<!--      <a-icon type="edit"/>-->
<!--    </div>-->


    <a-descriptions title="" bordered size="small" :column="{sm:2}">
      <a-descriptions-item label="设备状态">
        <a-badge v-if="!device.online" status="default" text="离线"/>
        <a-badge v-else-if="device.alarm" status="processing" color="red" text="报警"/>
        <a-badge v-else status="processing" color="blue" text="在线"/>
      </a-descriptions-item>
      <a-descriptions-item label="设备MAC">{{ device.name }}</a-descriptions-item>
      <a-descriptions-item v-if="device.online" label="运行时间">{{ runningTime }}</a-descriptions-item>
      <a-descriptions-item v-else label="离线时间">{{ device.lastOfflineTime }}</a-descriptions-item>
      <a-descriptions-item label="关联经销商">{{ device.vendorName || "无" }}</a-descriptions-item>
      <a-descriptions-item label="语音授权额度">{{ device.authorizationLimit || 0 }}</a-descriptions-item>
      <a-descriptions-item label="地址">{{ device.address }}

      </a-descriptions-item>

    </a-descriptions>

    <a-tabs type="card" style="margin-top: 20px;">
    <a-tab-pane key="1" tab="设备参数">
      <a-table
        class="dataMonitor-table"
        :columns="dataMonitorColumns"
        :dataSource="dataSource"
        :pagination="false"
      >
          <span slot="operation" slot-scope="text, record">
            <a-button size="small" type="primary" ghost v-if="record.subType=='RW' || record.subType=='W'"
                      @click="showModal(record)">控制</a-button>
          </span>


    </a-table>
  </a-tab-pane>
    <a-tab-pane key="2" tab="设备用户">
      <a-table
        class="dataMonitor-table"
        :columns="userColumns"
        :dataSource="userList"
        :pagination="false"
    >

      <template slot="owned" slot-scope="text, record">
        <a-tag color="green" v-if="record.owned">拥有者</a-tag>
        <a-tag v-else>使用者</a-tag>
      </template>

      <span slot="operation" slot-scope="text, record">
            <a-button size="small" type="primary" ghost
                      @click="clickUnbind(record)">解除绑定</a-button>
          </span>
    </a-table>
    </a-tab-pane>
    <a-tab-pane key="3" tab="语音授权记录">
      <a-table
        :columns="limitCloumns"
        :dataSource="limitList"
        :pagination="false"
        >
       </a-table>
      </a-tab-pane>
    </a-tabs>

    <DeviceParaControl ref="deviceParaControl"></DeviceParaControl>
    <DeviceEdit ref="deviceEdit" @success="getDeviceInfo"></DeviceEdit>

  </page-content>
</template>
<script>
import BaseDeviceView from "./BaseDeviceView";
import DeviceEdit from "./DeviceEdit";
import DeviceParaControl from "./DeviceParaControl";
import TlsJson from "./tls-fireplace"

export default {
  extends: BaseDeviceView,
  components: {
    DeviceEdit, DeviceParaControl
  },
  data() {
    return {
      dataSource: TlsJson.properties,
      userList: [],
      limitList:[]
    };
  },
  computed: {
    runningTime() {
      let ms = new Date().getTime() - new Date(this.device.lastOnlineTime).getTime()
      let minute = parseInt(ms / 1000 / 60)
      if (minute == 0) {
        return "1分钟"
      }
      let day = parseInt(minute / 1440)
      let hour = parseInt(minute / 60) % 24
      minute = minute % 60
      let result = ""
      if (day > 0) {
        result += day + "天"
      }
      if (hour > 0) {
        result += hour + "小时"
      }
      if (minute > 0) {
        result += minute + "分钟"
      }
      return result
    },
    userColumns() {
      return [
        {
          title: '昵称',
          dataIndex: 'nickName',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
        },
        {
          title: '手机号',
          dataIndex: 'phone'
        },
        {
          title: '用户类型',
          dataIndex: 'owned',
          scopedSlots: {customRender: 'owned'},
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 120
        }
      ]
    },
    dataMonitorColumns() {
      let self = this
      return [
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '标识符',
          dataIndex: 'code',
        },
        {
          title: '读写',
          dataIndex: 'subType'
        },
        {
          title: '数据类型',
          dataIndex: 'dataType',
        },
        {
          title: '当前值',
          dataIndex: 'code',
          width: 500,
          customRender(text, record) {
            let value = self.valueMap[text]
            if (value === undefined) {
              return ""
            }
            if (record.dataType == "ENUM" || record.dataType == "BOOL") {
              let info = record.specs.find(item => item.value == value + "")["name"]
              return `${value} (${info})`
            }
            if (record.dataType == "STRUCT") {
              return JSON.stringify(value)
            }
            
            // 2024-05-20 需求 如果标识符是WaterPressure，就除以10
            if(text=="WaterPressure" && value){
              value = (value/10).toFixed(1)
            }

            if(text=="BathWaterFlow" && value){
              value = (value/10).toFixed(1)
            }

            return `${value} (${record.specs.unit})`
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 120
        }
      ]
    },
    limitCloumns(){
      return [
        {
          title: '类型',
          dataIndex: 'type',
          customRender(text, record) {
            if(text==1){
              return "导入授权"
            }else if(text==2){
              return "用户消耗授权"
            }else if(text==4){
              return "附赠授权"
            }
          }
        },
        {
          title: '额度',
          dataIndex: 'limitChange',
          customRender(text, record) {
            return text>0 ? ("+"+text) : text
          }
        },
        {
          title: '用户昵称',
          dataIndex: 'userName',
        },
        {
          title: '用户账号',
          dataIndex: 'userPhone',
        },
        {
          title: '手机硬件编号',
          dataIndex: 'phoneId',
        },
        {
          title: '时间',
          dataIndex: 'createdAt',
        }
      ]
    }
  },
  created() {
    this.refresh()
  },

  methods: {
    refresh() {
      //获取设备信息
      this.getDeviceInfo()

      //获取绑定了设备的用户
      this.$get(`web/iot/device/${this.deviceId}/users`, {}).then(r => {
        this.userList = r.data;
      });

      //获取设备授权记录
      this.$get(`web/iot/device/authorization/list?deviceId=${this.deviceId}`).then(r=>{
        this.limitList = r.data
      })
    },

    editDevice() {
      this.$refs.deviceEdit.show(this.device)
    },

    showModal(record) {
      let value = this.valueMap[record.code] || "" 
      this.$refs.deviceParaControl.show(record, value, v => {
        this.control(record.code, v)
      })
    },

    clickUnbind(record){
      let that = this
      this.$confirm({
        title: '是否解除该用户的绑定关系?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete(`web/iot/device/unbind?userId=${record.userId}&deviceId=${record.deviceId}`).then(() => {
            that.$message.success('解绑成功')
            that.refresh()
          })
        }
      })
    }

  }
};
</script>
<style lang="less" scoped>
.device-name {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  cursor: pointer;
}

.device-name:hover {
  opacity: 0.5;
}

.device-info {
  margin-bottom: -8px;
}

.card {
  margin-bottom: 20px;
  background: #FCFCFC;
}

.curve-action {
  display: flex;
  align-items: center;
}

</style>
