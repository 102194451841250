export default {
	"profile":{
		"tslVersion":"1.2.0",
		"productKey":"p11ou9",
		"version":"20240314163124746"
	},
	"properties":[
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"24小时周定时",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"24小时日定时",
					"value":"2"
				},
				{
					"dataType":"ENUM",
					"name":"四段周定时",
					"value":"3"
				},
				{
					"dataType":"ENUM",
					"name":"四段日定时",
					"value":"4"
				},
				{
					"dataType":"ENUM",
					"name":"固定定时",
					"value":"5"
				}
			],
			"code":"TimerType",
			"dataType":"ENUM",
			"name":"定时类型",
			"subType":"R",
			"id":8,
			"sort":0,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开机",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关机",
					"value":"false"
				}
			],
			"code":"Switch",
			"dataType":"BOOL",
			"name":"开关",
			"subType":"RW",
			"id":1,
			"sort":0,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"夏季模式",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"冬季模式",
					"value":"1"
				}
			],
			"code":"SeasonMode",
			"dataType":"ENUM",
			"name":"季节模式",
			"subType":"RW",
			"id":9,
			"sort":1,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"30",
				"max":"60",
				"step":"1"
			},
			"code":"BathTargetTemperature",
			"dataType":"INT",
			"name":"沐浴目标温度",
			"subType":"RW",
			"id":10,
			"sort":2,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"30",
				"max":"80",
				"step":"1"
			},
			"code":"HeatingTargetTemperature",
			"dataType":"INT",
			"name":"采暖目标温度",
			"subType":"RW",
			"id":11,
			"sort":3,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"5",
				"max":"35",
				"step":"1"
			},
			"code":"IndoorTargetTemperature",
			"dataType":"INT",
			"name":"室内目标温度",
			"subType":"RW",
			"id":12,
			"sort":4,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"常规模式",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"舒适模式",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"智能浴模式",
					"value":"2"
				}
			],
			"code":"BathMode",
			"dataType":"ENUM",
			"name":"沐浴模式",
			"subType":"RW",
			"id":13,
			"sort":5,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"常规模式",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"旅行模式",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"经济模式",
					"value":"2"
				},
				{
					"dataType":"ENUM",
					"name":"睡眠模式",
					"value":"3"
				},
				{
					"dataType":"ENUM",
					"name":"定时模式",
					"value":"4"
				}
			],
			"code":"HeatingMode",
			"dataType":"ENUM",
			"name":"采暖模式",
			"subType":"RW",
			"id":14,
			"sort":6,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":{
						"unit":"",
						"min":"0",
						"max":"23",
						"step":"1"
					},
					"code":"Hour",
					"dataType":"INT",
					"name":"小时",
					"id":1
				},
				{
					"specs":{
						"unit":"",
						"min":"0",
						"max":"60",
						"step":"1"
					},
					"code":"Minute",
					"dataType":"INT",
					"name":"分钟",
					"id":2
				},
				{
					"specs":{
						"unit":"",
						"min":"1",
						"max":"7",
						"step":"1"
					},
					"code":"Week",
					"dataType":"INT",
					"name":"星期",
					"id":3
				}
			],
			"code":"Time",
			"dataType":"STRUCT",
			"name":"时间",
			"subType":"RW",
			"id":15,
			"sort":7,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"复位",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"无",
					"value":"false"
				}
			],
			"code":"Reset",
			"dataType":"BOOL",
			"name":"复位",
			"subType":"RW",
			"id":16,
			"sort":8,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":{
						"unit":"",
						"min":"0",
						"max":"255",
						"step":"1"
					},
					"code":"Byte1",
					"dataType":"INT",
					"name":"0时-7时",
					"id":1,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"0",
						"max":"255",
						"step":"1"
					},
					"code":"Byte2",
					"dataType":"INT",
					"name":"8时-15时",
					"id":2,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"0",
						"max":"255",
						"step":"1"
					},
					"code":"Byte3",
					"dataType":"INT",
					"name":"16-23时",
					"id":3,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"0",
						"max":"255",
						"step":"1"
					},
					"code":"Byte4",
					"dataType":"INT",
					"name":"星期",
					"id":4,
					"desc":""
				}
			],
			"code":"Timer24",
			"dataType":"STRUCT",
			"name":"24段定时",
			"subType":"RW",
			"id":17,
			"sort":9,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Flag",
					"dataType":"INT",
					"name":"标志",
					"id":1,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartHour",
					"dataType":"INT",
					"name":"起始时间",
					"id":2
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartMinute",
					"dataType":"INT",
					"name":"起始分钟",
					"id":3
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndHour",
					"dataType":"INT",
					"name":"结束时间",
					"id":4
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndMinute",
					"dataType":"INT",
					"name":"结束分钟",
					"id":5
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Temperature",
					"dataType":"INT",
					"name":"温度",
					"id":6
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Week",
					"dataType":"INT",
					"name":"日期",
					"id":7
				}
			],
			"code":"Timer4_1",
			"dataType":"STRUCT",
			"name":"4段定时1",
			"subType":"RW",
			"id":18,
			"sort":10,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Flag",
					"dataType":"INT",
					"name":"标志",
					"id":1,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartHour",
					"dataType":"INT",
					"name":"起始时间",
					"id":2
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartMinute",
					"dataType":"INT",
					"name":"起始分钟",
					"id":3
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndHour",
					"dataType":"INT",
					"name":"结束时间",
					"id":4
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndMinute",
					"dataType":"INT",
					"name":"结束分钟",
					"id":5
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Temperature",
					"dataType":"INT",
					"name":"温度",
					"id":6
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Week",
					"dataType":"INT",
					"name":"日期",
					"id":7
				}
			],
			"code":"Timer4_2",
			"dataType":"STRUCT",
			"name":"4段定时2",
			"subType":"RW",
			"id":19,
			"sort":11,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Flag",
					"dataType":"INT",
					"name":"标志",
					"id":1,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartHour",
					"dataType":"INT",
					"name":"起始时间",
					"id":2
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartMinute",
					"dataType":"INT",
					"name":"起始分钟",
					"id":3
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndHour",
					"dataType":"INT",
					"name":"结束时间",
					"id":4
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndMinute",
					"dataType":"INT",
					"name":"结束分钟",
					"id":5
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Temperature",
					"dataType":"INT",
					"name":"温度",
					"id":6
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Week",
					"dataType":"INT",
					"name":"日期",
					"id":7
				}
			],
			"code":"Timer4_3",
			"dataType":"STRUCT",
			"name":"4段定时3",
			"subType":"RW",
			"id":20,
			"sort":12,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Flag",
					"dataType":"INT",
					"name":"标志",
					"id":1,
					"desc":""
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartHour",
					"dataType":"INT",
					"name":"起始时间",
					"id":2
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"StartMinute",
					"dataType":"INT",
					"name":"起始分钟",
					"id":3
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndHour",
					"dataType":"INT",
					"name":"结束时间",
					"id":4
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"EndMinute",
					"dataType":"INT",
					"name":"结束分钟",
					"id":5
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Temperature",
					"dataType":"INT",
					"name":"温度",
					"id":6
				},
				{
					"specs":{
						"unit":"",
						"min":"",
						"max":"",
						"step":""
					},
					"code":"Week",
					"dataType":"INT",
					"name":"日期",
					"id":7
				}
			],
			"code":"Timer4_4",
			"dataType":"STRUCT",
			"name":"4段定时4",
			"subType":"RW",
			"id":21,
			"sort":13,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"P0",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"P1",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"P2",
					"value":"2"
				},
				{
					"dataType":"ENUM",
					"name":"P3",
					"value":"3"
				},
				{
					"dataType":"ENUM",
					"name":"P4",
					"value":"4"
				},
				{
					"dataType":"ENUM",
					"name":"P5",
					"value":"5"
				}
			],
			"code":"FixedMode",
			"dataType":"ENUM",
			"name":"固定模式",
			"subType":"RW",
			"id":22,
			"sort":14,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"周一",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"周二",
					"value":"2"
				},
				{
					"dataType":"ENUM",
					"name":"周三",
					"value":"3"
				},
				{
					"dataType":"ENUM",
					"name":"周四",
					"value":"4"
				},
				{
					"dataType":"ENUM",
					"name":"周五",
					"value":"5"
				},
				{
					"dataType":"ENUM",
					"name":"周六",
					"value":"6"
				},
				{
					"dataType":"ENUM",
					"name":"周日",
					"value":"7"
				}
			],
			"code":"QueryTimer",
			"dataType":"ENUM",
			"name":"定时查询",
			"subType":"RW",
			"id":23,
			"sort":15,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"两用机",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"单暖机",
					"value":"1"
				}
			],
			"code":"DeviceType",
			"dataType":"ENUM",
			"name":"机器类型",
			"subType":"R",
			"id":24,
			"sort":16,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"板换机",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"套管机",
					"value":"1"
				}
			],
			"code":"HeatExchangerType",
			"dataType":"ENUM",
			"name":"换热器类型",
			"subType":"R",
			"id":25,
			"sort":17,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"开关型",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"频率型",
					"value":"1"
				}
			],
			"code":"WaterFlowType",
			"dataType":"ENUM",
			"name":"水流类型",
			"subType":"R",
			"id":26,
			"sort":18,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"散热片方式",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"地暖方式",
					"value":"1"
				}
			],
			"code":"HeatingMethod",
			"dataType":"ENUM",
			"name":"采暖方式",
			"subType":"R",
			"id":27,
			"sort":19,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"间歇运行",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"一直运行",
					"value":"1"
				}
			],
			"code":"WaterPumpOperationMode",
			"dataType":"ENUM",
			"name":"水泵运行模式",
			"subType":"RW",
			"id":28,
			"sort":20,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"bar",
				"min":"0",
				"max":"4",
				"step":"1"
			},
			"code":"WaterPressure",
			"dataType":"INT",
			"name":"水压值",
			"subType":"R",
			"id":29,
			"sort":21,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"液化气",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"天然气",
					"value":"1"
				}
			],
			"code":"GasType",
			"dataType":"ENUM",
			"name":"气体类型",
			"subType":"R",
			"id":30,
			"sort":22,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"0",
				"max":"100",
				"step":"1"
			},
			"code":"ReturnWaterTemperature",
			"dataType":"INT",
			"name":"回水温度",
			"subType":"R",
			"id":31,
			"sort":23,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"0",
				"max":"30",
				"step":"1"
			},
			"code":"ClimateCompensationTemperature",
			"dataType":"INT",
			"name":"气候补偿舒适设置",
			"subType":"RW",
			"id":32,
			"sort":24,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"5",
				"max":"30",
				"step":"1"
			},
			"code":"HeatingReturnTemperature",
			"dataType":"INT",
			"name":"供暖回差温度设置",
			"subType":"RW",
			"id":33,
			"sort":25,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"正常状态",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"二级防冻状态",
					"value":"1"
				}
			],
			"code":"SecondaryAntifreezeStatus",
			"dataType":"ENUM",
			"name":"二级防冻状态",
			"subType":"R",
			"id":34,
			"sort":26,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"未用卫浴水",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"有用卫浴水",
					"value":"false"
				}
			],
			"code":"WaterFlowSwitch",
			"dataType":"BOOL",
			"name":"水流开关",
			"subType":"R",
			"id":35,
			"sort":27,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"闭合",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"断开",
					"value":"false"
				}
			],
			"code":"RoomThermostatStatus",
			"dataType":"BOOL",
			"name":"房间温控器",
			"subType":"R",
			"id":36,
			"sort":28,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"有火",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"无火",
					"value":"false"
				}
			],
			"code":"FlameStatus",
			"dataType":"BOOL",
			"name":"火焰状态",
			"subType":"R",
			"id":37,
			"sort":29,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"0档",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"1档",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"2档",
					"value":"2"
				},
				{
					"dataType":"ENUM",
					"name":"3档",
					"value":"3"
				},
				{
					"dataType":"ENUM",
					"name":"4档",
					"value":"4"
				},
				{
					"dataType":"ENUM",
					"name":"5档",
					"value":"5"
				},
				{
					"dataType":"ENUM",
					"name":"6档",
					"value":"6"
				}
			],
			"code":"FlameLevel",
			"dataType":"ENUM",
			"name":"火焰大小",
			"subType":"R",
			"id":38,
			"sort":30,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"正常状态",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"防冻状态",
					"value":"1"
				}
			],
			"code":"AntifreezeStatus",
			"dataType":"ENUM",
			"name":"防冻状态",
			"subType":"R",
			"id":39,
			"sort":31,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"0",
				"max":"100",
				"step":"1"
			},
			"code":"OutdoorTemperature",
			"dataType":"INT",
			"name":"室外温度",
			"subType":"RW",
			"id":40,
			"sort":32,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"0",
				"max":"100",
				"step":"1"
			},
			"code":"BathWaterTemperature",
			"dataType":"INT",
			"name":"卫浴出水实际温度",
			"subType":"R",
			"id":41,
			"sort":33,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"0",
				"max":"100",
				"step":"1"
			},
			"code":"HeatingWaterTemperature",
			"dataType":"INT",
			"name":"供暖出水实际温度",
			"subType":"R",
			"id":42,
			"sort":34,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"有",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"无",
					"value":"false"
				}
			],
			"code":"OutdoorTemperatureSensor",
			"dataType":"BOOL",
			"name":"室外温度传感器",
			"subType":"R",
			"id":43,
			"sort":35,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"周期模式",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"稳火模式",
					"value":"1"
				}
			],
			"code":"HeatingCombustionMode",
			"dataType":"ENUM",
			"name":"采暖燃烧模式",
			"subType":"RW",
			"id":44,
			"sort":36,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"m³",
				"min":"",
				"max":"",
				"step":""
			},
			"code":"GasConsumption",
			"dataType":"INT",
			"name":"耗气量数据",
			"subType":"R",
			"id":45,
			"sort":37,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"有",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"无",
					"value":"false"
				}
			],
			"code":"ReturnWaterTemperatureSensor",
			"dataType":"BOOL",
			"name":"回水温度传感器",
			"subType":"R",
			"id":46,
			"sort":38,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"无",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"有",
					"value":"false"
				}
			],
			"code":"SolarFunction",
			"dataType":"BOOL",
			"name":"太阳能功能",
			"subType":"R",
			"id":47,
			"sort":39,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"无",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"有",
					"value":"false"
				}
			],
			"code":"PressureSensor",
			"dataType":"BOOL",
			"name":"压力传感器",
			"subType":"R",
			"id":48,
			"sort":40,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"℃",
				"min":"",
				"max":"",
				"step":""
			},
			"code":"IndoorTemperature",
			"dataType":"INT",
			"name":"室内实际温度",
			"subType":"R",
			"id":49,
			"sort":41,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"IndoorTargetTemperature",
					"dataType":"BOOL",
					"name":"室内目标温度",
					"id":1
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"ComfortMode",
					"dataType":"BOOL",
					"name":"舒适模式",
					"id":2
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"SmartBathMode",
					"dataType":"BOOL",
					"name":"智能浴模式",
					"id":3
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"TravelMode",
					"dataType":"BOOL",
					"name":"旅行模式",
					"id":4
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"EconomicMode",
					"dataType":"BOOL",
					"name":"经济模式",
					"id":5
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"SleepMode",
					"dataType":"BOOL",
					"name":"睡眠模式",
					"id":6
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"ZeroColdWaterMode",
					"dataType":"BOOL",
					"name":"零冷水模式",
					"id":7
				},
				{
					"specs":[
						{
							"dataType":"BOOL",
							"name":"有",
							"value":"true"
						},
						{
							"dataType":"BOOL",
							"name":"无",
							"value":"false"
						}
					],
					"code":"BoostMode",
					"dataType":"BOOL",
					"name":"增压模式",
					"id":8
				}
			],
			"code":"DeviceFunction",
			"dataType":"STRUCT",
			"name":"设备功能",
			"subType":"R",
			"id":50,
			"sort":42,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"W",
				"min":"0",
				"max":"249",
				"step":"1"
			},
			"code":"MaxPower",
			"dataType":"INT",
			"name":"最大功率值",
			"subType":"RW",
			"id":51,
			"sort":43,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"W",
				"min":"0",
				"max":"249",
				"step":"1"
			},
			"code":"MinPower",
			"dataType":"INT",
			"name":"最小功率值",
			"subType":"RW",
			"id":52,
			"sort":44,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"W",
				"min":"0",
				"max":"249",
				"step":"1"
			},
			"code":"IgnitionPower",
			"dataType":"INT",
			"name":"点火功率值",
			"subType":"RW",
			"id":53,
			"sort":45,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"ENUM",
					"name":"无故障",
					"value":"0"
				},
				{
					"dataType":"ENUM",
					"name":"E1",
					"value":"1"
				},
				{
					"dataType":"ENUM",
					"name":"E2",
					"value":"2"
				},
				{
					"dataType":"ENUM",
					"name":"E3",
					"value":"3"
				},
				{
					"dataType":"ENUM",
					"name":"E4",
					"value":"4"
				},
				{
					"dataType":"ENUM",
					"name":"E5",
					"value":"5"
				},
				{
					"dataType":"ENUM",
					"name":"E6",
					"value":"6"
				},
				{
					"dataType":"ENUM",
					"name":"E7",
					"value":"7"
				},
				{
					"dataType":"ENUM",
					"name":"E8",
					"value":"8"
				},
				{
					"dataType":"ENUM",
					"name":"E9",
					"value":"9"
				},
				{
					"dataType":"ENUM",
					"name":"EA",
					"value":"10"
				},
				{
					"dataType":"ENUM",
					"name":"EB",
					"value":"11"
				},
				{
					"dataType":"ENUM",
					"name":"E0",
					"value":"12"
				},
				{
					"dataType":"ENUM",
					"name":"ED",
					"value":"13"
				},
				{
					"dataType":"ENUM",
					"name":"EE",
					"value":"14"
				},
				{
					"dataType":"ENUM",
					"name":"EF",
					"value":"15"
				},
				{
					"dataType":"ENUM",
					"name":"EC",
					"value":"16"
				},
				{
					"dataType":"ENUM",
					"name":"EH",
					"value":"17"
				}
			],
			"code":"ErrorCode",
			"dataType":"ENUM",
			"name":"故障代码",
			"subType":"R",
			"id":54,
			"sort":46,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"length":"16"
			},
			"code":"authCode",
			"dataType":"TEXT",
			"name":"authCode",
			"subType":"R",
			"id":55,
			"sort":47,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"length":"16"
			},
			"code":"Pid",
			"dataType":"TEXT",
			"name":"PID",
			"subType":"R",
			"id":57,
			"sort":49,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关",
					"value":"false"
				}
			],
			"code":"ComfortModeSwitch",
			"dataType":"BOOL",
			"name":"舒适模式",
			"subType":"RW",
			"id":58,
			"sort":50,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关",
					"value":"false"
				}
			],
			"code":"SmartBathModeSwitch",
			"dataType":"BOOL",
			"name":"智能浴模式",
			"subType":"RW",
			"id":59,
			"sort":51,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关",
					"value":"false"
				}
			],
			"code":"TravelModeSwitch",
			"dataType":"BOOL",
			"name":"旅行模式",
			"subType":"RW",
			"id":60,
			"sort":52,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关",
					"value":"false"
				}
			],
			"code":"EconomicModeSwitch",
			"dataType":"BOOL",
			"name":"经济模式",
			"subType":"RW",
			"id":61,
			"sort":53,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关",
					"value":"false"
				}
			],
			"code":"SleepModeSwitch",
			"dataType":"BOOL",
			"name":"睡眠模式",
			"subType":"RW",
			"id":62,
			"sort":54,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":[
				{
					"dataType":"BOOL",
					"name":"开",
					"value":"true"
				},
				{
					"dataType":"BOOL",
					"name":"关",
					"value":"false"
				}
			],
			"code":"TimerModeSwitch",
			"dataType":"BOOL",
			"name":"定时模式",
			"subType":"RW",
			"id":63,
			"sort":55,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"L/min",
				"min":"0",
				"max":"255",
				"step":"1"
			},
			"code":"BathWaterFlow",
			"dataType":"INT",
			"name":"卫浴水流量",
			"subType":"R",
			"id":65,
			"sort":57,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"length":"8"
			},
			"code":"BackstagePassword",
			"dataType":"TEXT",
			"name":"后台管理密码",
			"subType":"W",
			"id":66,
			"sort":58,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"",
				"min":"0",
				"max":"65535",
				"step":"1"
			},
			"code":"BackstageFunction",
			"dataType":"INT",
			"name":"后台管理平台功能参考",
			"subType":"R",
			"id":67,
			"sort":59,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"",
				"min":"0",
				"max":"15",
				"step":"1"
			},
			"code":"ProtectionLevel",
			"dataType":"INT",
			"name":"烟道堵塞保护等级",
			"subType":"RW",
			"id":68,
			"sort":60,
			"type":"PROPERTY",
			"desc":""
		},
		{
			"specs":{
				"unit":"",
				"min":"0",
				"max":"100",
				"step":"1"
			},
			"code":"BathInletWaterTemperature",
			"dataType":"INT",
			"name":"洗浴进水温度",
			"subType":"R",
			"id":69,
			"sort":61,
			"type":"PROPERTY",
			"desc":""
		}
	],
	"events":[
		{
			"outputData":[
				{
					"$ref":"#/properties/id/55"
				}
			],
			"code":"authCodeCfg",
			"name":"authCodeCfg",
			"subType":"INFO",
			"id":56,
			"sort":48,
			"type":"EVENT",
			"desc":""
		},
		{
			"code":"QueryTime",
			"name":"查询实时时钟",
			"subType":"INFO",
			"id":64,
			"sort":56,
			"type":"EVENT",
			"desc":""
		}
	]
}
